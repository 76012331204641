<template>
  <div class="header-navigation">
    <div class="header-navigation__wrapper">
      <div class="header-navigation__button"
           @mouseenter="openRegionMenu"
           @mouseleave="closeRegionMenu">об области
        <div class="header-navigation__submenu"
             @mouseenter="refreshTimeoutRegionMenu"
             @mouseleave="closeRegionMenu"
             v-if="isOpenRegionMenu && $viewport.desktop">
          <span class="header-navigation__submenu-link"
                v-on:click.stop="changeURL('/municipality/region')">Общая информация</span>
          <span class="header-navigation__submenu-link"
                v-on:click.stop="openPopup">Города и районы
          </span>
          <span class="header-navigation__submenu-link"
                v-on:click.stop="changeURL('/logistics')">Как добраться
          </span>
        </div>
        <div class="header-navigation__submenu"
             v-if="isOpenRegionMenu && $viewport.mobile">
          <span class="header-navigation__submenu-link"
                v-on:click.stop="changeURL('/municipality/region')">Общая информация</span>
          <a class="header-navigation__submenu-link"
             v-on:click.stop="openPopup">Города и районы</a>
          <span class="header-navigation__submenu-link"
                v-on:click.stop="changeURL('/logistics')">Как добраться
          </span>
        </div>
      </div>
      <!--  -->
      <router-link class="header-navigation__button"
                   to="/places">Где отдохнуть
      </router-link>
      <!--  -->
      <div class="header-navigation__button"
           @mouseenter="openMenu"
           @mouseleave="closeMenu">Что посмотреть
        <div class="header-navigation__submenu"
             @mouseenter="refreshTimeoutPlacesMenu"
             @mouseleave="closeMenu"
             v-if="open && $viewport.desktop">
          <span class="header-navigation__submenu-link"
                v-on:click.stop="changeURL('/trips')">Маршруты</span>
          <span class="header-navigation__submenu-link"
                v-on:click.stop="changeURL('/excursions')">Туры и экскурсии
          </span>
          <span class="header-navigation__submenu-link"
                v-on:click.stop="changeURL('/news')">Афиша
          </span>
        </div>
      </div>
      <div class="header-navigation__submenu"
           v-if="open && $viewport.mobile">
        <span class="header-navigation__submenu-link"
              v-on:click.stop="changeURL('/trips')">Маршруты</span>
        <span class="header-navigation__submenu-link"
              v-on:click.stop="changeURL('/excursions')">Туры и экскурсии
          </span>
        <span class="header-navigation__submenu-link"
              v-on:click.stop="changeURL('/news')">Афиша
          </span>
      </div>
      <router-link class="header-navigation__button"
                   to="/interactive-map">интерактивная карта
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderNavigation',
  data() {
    return {
      regionMenuTimeout: null,
      placesMenuTimeout: null,
      isOpenRegionMenu: false,
      open: false,
    };
  },
  methods: {
    openMenu() {
      this.isOpenRegionMenu = false;
      this.open = true;
    },
    refreshTimeoutPlacesMenu() {
      clearTimeout(this.placesMenuTimeout);
    },
    closeMenu() {
      clearTimeout(this.placesMenuTimeout);
      this.placesMenuTimeout = setTimeout(() => {
        this.open = false;
      }, 2000);
    },
    openRegionMenu() {
      this.open = false;
      this.isOpenRegionMenu = true;
    },
    refreshTimeoutRegionMenu() {
      clearTimeout(this.regionMenuTimeout);
    },
    closeRegionMenu() {
      clearTimeout(this.regionMenuTimeout);
      this.regionMenuTimeout = setTimeout(() => {
        this.isOpenRegionMenu = false;
      }, 2000);
    },
    changeURL(link) {
      this.isOpenRegionMenu = false;
      this.open = false;
      this.$router.push(link);
    },
    openPopup() {
      this.closeMenu();
      this.closeRegionMenu();
      this.$openPopupChooseMunicipality();
    },
  },
};
</script>
