<template>
    <div class="header-sign-in">
        <div class="header-sign-in__wrapper">
            <!--      <Button class=""-->
            <!--              v-on:click.native="signIn"-->
            <!--              color="green">Войти-->
            <!--      </Button>-->
            <!--            <router-link class="button button__green header-sign-in__button"-->
            <!--                         to="/login/keycloak">-->
            <!--                Войти-->
            <!--            </router-link>-->
            <a class="button button__green header-sign-in__button"
               href="/login/keycloak">Войти</a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'HeaderSignIn',
  // methods: {
  //   signIn() {
  //     this.$store.dispatch('GET_TOKEN_FROM_SERVER');
  //   },
  // },
};
</script>
