<template>
  <label class="header-search-bar header__flexed"
         for="header-search-bar-input">
    <div class="header-search-bar__wrapper header__flexed">
      <div class="header__icon">
        <Icon class="header__icon-loupe"
              viewport="0 0 24 24"
              xlink="loupe"/>
      </div>
      <input class="header-search-bar__input header__flexed"
             v-model="query"
             autocomplete="off"
             id="header-search-bar-input"/>
      <div class="header__icon icon--close"
           v-on:click.prevent="closeSearchBar">
        <Icon class="header__icon-search-bar-close"
              viewport="0 0 24 24"
              xlink="search-bar-close"/>
      </div>
    </div>
  </label>
</template>

<script>
export default {
  name: 'HeaderSearchBar',
  data() {
    return {
      query: '',
    };
  },
  methods: {
    closeSearchBar() {
      this.$store.commit('CLEAR_SEARCH');
      this.$store.commit('CLOSE_SEARCH_BAR');
    },
  },
  watch: {
    query() {
      if (this.query.length > 3) {
        this.$store.dispatch('REQUEST_SEARCH_FROM_SERVER',
          { params: { query: this.query } });
      }
    },
  },
};
</script>
