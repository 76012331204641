<template>
    <div class="header">
        <div class="header__wrapper">
            <Row v-if="$viewport.desktop"
                 align="center">
                <router-link class="header__logotype"
                             to="/">
                    <Icon class="header__logotype-icon"
                          xlink="ag"
                          viewport="0 0 80 80"/>
                </router-link>
                <div class="header__sections">
                    <div class="header__section">
                        <div class="header__guidline"
                             v-on:click="openMenu"
                             v-click-outside="closeMenu">
                            <span class="header__guidline-text">Гид-путеводитель по Томску и Томской
                              области</span>
                            <Icon class="header__guidline-icon"
                                  :class="open ? 'header__guidline-icon-active' : ''"
                                  xlink="arrow-navigation"
                                  :rotate="open ? 0 : 180"
                                  viewport="0 0 20 20"
                                  :width="24"
                                  :height="24"/>
                            <div v-if="open"
                                 class="header__guidline-list">
                                <Row v-for="item in items"
                                     :key="item.id"
                                     class="header__guidline-row"
                                     wrap>
                                    <div class="header__guidline-list-icon">
                                        <Icon :xlink="item.xlink"
                                              :viewport="item.xlink === 'ag-menu'
                                              ? '0 0 40 40' : '0 0 48 48'"/>
                                    </div>
                                    <a class="header__guidline-link"
                                       :href="item.href">{{item.title}}</a>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div class="header__section header__flexed">
                        <Transition mode="out-in"
                                    name="fade">
                            <HeaderSearchBar v-if="$header.openSearchBar"/>
                            <HeaderNavigation v-else/>
                        </Transition>
                    </div>
                    <div class="header__section"
                         v-if="!$header.openSearchBar">
                        <div class="header__icon"
                             v-on:click="openSearchBar">
                            <Icon class="header__icon-loupe"
                                  viewport="0 0 24 24"
                                  xlink="loupe"/>
                        </div>
                    </div>
                    <div class="header__section">
                        <div class="header__icon">
                            <a href="mailto:tour@tomsk.life" target="_blank">
                                <Icon class="header__icon-feedback"
                                      viewport="0 0 24 24"
                                      xlink="feedback"/>
                            </a>
                        </div>
                    </div>
                </div>
                <HeaderUserAvatar v-if="$user.logged"/>
                <HeaderSignIn v-else/>
            </Row>
            <Row v-if="$viewport.mobile"
                 align="center"
                 justify="between"
                 class="header__mobile"
                 :class="{ '_search': $header.openSearchBar }">
                <Row align="center">
                    <router-link class="header__logotype"
                                 to="/">
                        <Icon class="header__logotype-icon"
                              xlink="ag-mobile"
                              viewport="0 0 56 56"/>
                    </router-link>
                    <Row class="burger-menu__icon-holder"
                         align="center"
                         justify="center"
                         v-on:click.native="openMenuBurger"
                         v-if="!$header.openSearchBar"
                    >
                        <Icon class="burger-menu__icon"
                              :viewport="$header.openBurger ? '0 0 20 20' : '0 0 24 24'"
                              :xlink="$header.openBurger ? 'close' : 'burger-menu'"/>
                    </Row>
                    <Row class="search-menu__icon-holder"
                         align="center"
                         justify="center"
                         v-on:click.native="openSearchBar">
                        <Icon class="header__icon-loupe"
                              viewport="0 0 24 24"
                              xlink="loupe"/>
                    </Row>
                    <div class="header__section header__flexed">
                      <Transition mode="out-in"
                                  name="fade">
                        <HeaderSearchBar v-if="$header.openSearchBar"/>
                      </Transition>
                    </div>
                </Row>
                <HeaderUserAvatar v-if="$user.logged"/>
                <HeaderSignIn v-else/>
                <div v-if="$header.openBurger"
                     class="header__mobile-menu">
                    <Column class="header__mobile-menu-wrapper">
                        <HeaderNavigation/>
                        <Divider/>
                        <Row v-for="item in items"
                             :key="item.id"
                             class="header__guidline-row">
                            <div class="header__guidline-list-icon">
                                <Icon :xlink="item.xlink"
                                      viewport="0 0 48 48"/>
                            </div>
                            <a class="header__guidline-link"
                               :href="item.href">{{item.title}}</a>
                        </Row>
                    </Column>
                </div>
            </Row>
        </div>
        <div v-if="isShowDisclaimer" class="header__disclaimer-block">
            <div class="container">
                <p>{{disclaimerText}} <a href="mailto:support@tomsk.life">support@tomsk.life</a></p>
                <div class="header__disclaimer-block__button-close"
                     v-on:click="close">
                    <Icon viewport="0 0 20 20"
                          xlink="close"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderSignIn from './HeaderSignIn.vue';
import HeaderUserAvatar from './HeaderUserAvatar.vue';
import HeaderNavigation from './HeaderNavigation.vue';
import HeaderSearchBar from './HeaderSearchBar.vue';

export default {
  name: 'Header',
  components: {
    HeaderSearchBar,
    HeaderNavigation,
    HeaderUserAvatar,
    HeaderSignIn,
  },
  data() {
    return {
      disclaimerText: 'Уважаемые пользователи, сервисы цифровой платформы Томской области работают в пилотном режиме. Вся функциональность сервисов доступна зарегистрированным пользователям. Регистрируйтесь в два клика, используя портал госуслуг! Все предложения по улучшению качества сервисов можно отсылать на адрес',
      isShowDisclaimer: false,
      open: false,
      items: [{
        title: 'Личный кабинет',
        href: 'https://account.tomsk.life/',
        xlink: 'servis-user',
      }, {
        title: 'Активный житель',
        href: 'https://act.tomsk.life/',
        xlink: 'servis-ag',
      }, {
        title: 'Гид-путеводитель',
        href: 'http://tour.tomsk.life/',
        xlink: 'ag-menu',
      }, {
        title: 'Экспортный каталог',
        href: 'https://export.tomsk.life/',
        xlink: 'servis-government',
      }, {
        title: 'Меры поддержки предпринимательства',
        href: 'http://biz.tomsk.life/',
        xlink: 'servis-marketplace',
      }, {
        title: 'Цифровая лаборатория больших данных',
        href: 'https://data.tomsk.life/',
        xlink: 'servis-data',
      }],
    };
  },
  methods: {
    openSearchBar() {
      this.$store.commit('OPEN_SEARCH_BAR');
    },
    openMenu() {
      this.open = !this.open;
    },
    closeMenu() {
      this.open = false;
    },
    openMenuBurger() {
      this.$store.commit('TOGGLE_BURGER');
    },
    close() {
      this.isShowDisclaimer = false;
      document.cookie = `${encodeURIComponent('disclaimer-hidden')}=${encodeURIComponent('true')};path=/`;
    },
  },
  watch: {
    $route() {
      this.$store.commit('CLOSE_USER_MENU');
      this.$store.commit('CLOSE_SEARCH_BAR');
      this.$store.commit('CLOSE_BURGER');
    },
  },
};
</script>
