<template>
  <div v-if="$viewport.desktop"
       v-on:click="openAccountMenu"
       class="header-user-avatar">
    <div :style="{width: `${this.size}px`, height: `${this.size}px`}"
         class="header-user-avatar__wrapper"
         v-on:click="toggleUserMenu">
      <img class="header-user-avatar__avatar"
           :src="$user.avatar"
           alt=""/>
    </div>
  </div>
  <div v-else
       v-on:click="openAccountMenu"
       class="header-user-avatar">
    <div :style="{width: `${this.size}px`, height: `${this.size}px`}"
         class="header-user-avatar__wrapper"
         v-on:click="toggleUserMenu">
      <img class="header-user-avatar__avatar"
           :src="$user.avatar"
           alt=""/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderUserAvatar',
  props: {
    size: {
      type: Number,
      default: 50,
    },
  },
  methods: {
    openAccountMenu() {
      this.$store.commit('CLOSE_POPUP');
      this.$store.commit('OPEN_POPUP', {
        width: this.$viewport.desktop ? 350 : '100%',
        height: '100vh',
        type: 'AccountMenu',
        horizontal: 'right',
      });
    },
    openUserMenu() {
      this.$store.commit('OPEN_USER_MENU');
    },
    closeUserMenu() {
      this.$store.commit('CLOSE_USER_MENU');
    },
    toggleUserMenu() {
      this.$store.commit('TOGGLE_USER_MENU');
    },
  },
};
</script>
